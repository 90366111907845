import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/MDXLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`PROGRAMMING EXPERIENCE`}</h1>
    <h2>{`BrightHR`}</h2>
    <h3>{`September 2021 - July 2022`}</h3>
    <h2>{`As a Frontend developer, my responsibilities included:`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Performing UI changes across various Bright products, using React
and TailwindCSS.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Writing unit tests in Jest with React Testing Library to ensure frontend
repositories were kept above required code coverage.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Performing various codebase maintenance tasks including; converting legacy
styled-components to TailwindCSS, rewriting Enzyme unit tests to React
Testing Library and converting React class components to using React Hooks.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Working with UX designers collaboratively on new product designs, giving detailed opinions and reaching concensus based upon scalability and consistency across the product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Closely working with Quality Assurance Analysts by giving detailed
demonstrations and collaboratively theorising solutions for squashing bugs in UI with a user
first approach.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Working in an Agile environment with daily standups, UX show and tells, POA
meetings and bi-weekly retrospectives.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Periodically giving product demonstrations to Research and Development and
Customer Service teams.`}</p>
      </li>
    </ul>
    <h2>{`Some of the tasks and projects I worked on included:`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Migrating BrightHR’s change/reset password functionality from the primary
WebApp to BrightHR’s User Account Web App.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Implementing new UI and features for BrightHR’s Account Web App.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Elevating BrightHR’s Account Web App’s code coverage from 25% to 80%.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Migrating PoP!, BrightHR's dedicated expense tracking application from mobile to primary Web Application by
coding new UI and integrating with PoP!’s RESTful API.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Implementing UI improvements to various areas of the product
specifically addressing unit testing and responsive design using media queries.`}</p>
      </li>
    </ul>
    <h1>{`EDUCATION`}</h1>
    <h2>{`Manchester Metropolitan University`}</h2>
    <p>{` Sept 2015 - June 2018`}</p>
    <p>{`BA International Politics `}<strong parentName="p">{`|`}</strong>{` 2:1`}</p>
    <h1>{`WORK EXPERIENCE`}</h1>
    <h2>{`Junior Frontend Developer`}</h2>
    <h3>{`BRIGHTHR`}</h3>
    <p>{` Sept 2021 - July 2022`}</p>
    <h2>{`10+ Years Experience in Hospitality`}</h2>
    <p>{`Sept 2008 - June 2021`}</p>
    <p>{`Whilst there is little overlap between Hospitality and Software, I have gained certain skills from a variety of roles which can be invaluable in any team-based work environment.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Insight into efficient teamwork producing a superior product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Multi-tasking and time management abilities in an environment where both are key to a successful service.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Interpersonal skills which have allowed me to empathise, negotiate and offer constructive criticism with my co-workers where appropriate.`}</p>
      </li>
    </ul>
    <h1>{`EMPLOYMENT HISTORY`}</h1>
    <h3>{`BRIGHTHR`}</h3>
    <p>{`Sept 2021 - July 2022`}</p>
    <h3>{`RUDYS NEAPOLITAN PIZZA`}</h3>
    <p>{`June 2017 - June 2021`}</p>
    <h3>{`OSTLERS BAR AND BISTRO`}</h3>
    <p>{`Oct 2014 - Dec 2015`}</p>
    <h3>{`THE ANCHOR INN`}</h3>
    <p>{`Jan 2014 - Sept 2015`}</p>
    <h3>{`BROWNS WINE BAR`}</h3>
    <p>{`Nov 2012 - Jan 2014`}</p>
    <h3>{`CHESTER’S MEXICAN RESTAURANT`}</h3>
    <p>{`Sept 2008 - Apr 2012`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      